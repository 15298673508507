<template>
  <psyhSpec></psyhSpec>
</template>

<script>
const psyhSpec = () =>
  import("../../../components/dashboard/psyhDashboard/Specialization");
export default {
  name: "Specialization",
  components: {
    psyhSpec,
  },
};
</script>

<style scoped></style>
